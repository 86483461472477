/* colors */
$inactive: #9197a3;
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$danger: #d9534f;
$success: #47a447;
$primary: #428bca;
$info: #5bc0de;
$warning: #f0ad4e;
$secondary: #6c757d;
$default: #ebebeb;

body {
  background: #484848 url(assets/blacklinen.png) repeat;
  padding-top: 3em;
  padding-bottom: 70px;
  font-size: 0.9rem;
}

body#devReporting {
  padding-top: 0;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
td,
span,
div,
button {
  font-family: 'Tauri', Helvetica !important;
}

span.fa,
span.fas,
span.far,
span.fab,
span.fal,
span.fad {
  font-family: 'Font Awesome 5 Free' !important;
}

.form-control::placeholder {
  opacity: 0.7;
  font-size: 0.9rem;
  font-style: italic;
}

.btn {
  font-size: 0.9rem;
}

.content-box {
  background-color: #fff;
  margin: 10px auto 5px auto;
  padding: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  border: 5px solid #e8e8e8;
}

table.table tr td,
.table.table tr th {
  vertical-align: middle;
}

.table-container {
  overflow-x: scroll;
  width: 100%;
}

.pagination {
  margin: 5px 0;
}

.nav-tabs {
  margin: 0 0 15px 0;
}

.modal.fade {
  -webkit-transition: opacity .2s linear, none;
  -moz-transition: opacity .2s linear, none;
  -o-transition: opacity .2s linear, none;
  transition: opacity .2s linear, none;
}

select[multiple] {
  margin: 5px 0 0 0;
}

#note {
  width: 450px;
  height: 200px;
}

.action-button {
  display: block;
  padding: 20px;
  margin: 20px auto;
  border-radius: 240px;
  height: 490px;
  width: 490px;
  font-size: 45px;
  color: #fff;
  background-color: #d2322d;
  border: none;
}

.action-button:hover,
.action-button:focus,
.action-button:active {
  color: #fff;
  background-color: #d9534f;
  border: none;
  outline: none;
}

.ticket-button {
  display: block;
  padding: 20px;
  margin: 20px auto;
  border-radius: 240px;
  height: 245px;
  width: 245px;
  font-size: 25px;
  color: #fff;
  background-color: #d2322d;
  border: none;

  :hover,
  :focus,
  :active {
    color: #fff;
    background-color: #d9534f;
    border: none;
    outline: none;
  }
}

.customer-button {
  padding: 20px 75px 20px 75px;
  border-radius: 10px;
  font-size: 30px;
  color: #fff;
  background-color: #d2322d;
  border: none;
  height: 110px;

  :hover,
  :focus,
  :active {
    color: #fff;
    background-color: #d9534f;
    border: none;
    outline: none;
  }
}

.clear-fix {
  clear: both;
}

.clear-left {
  clear: left;
}

.modal {
  .modal-dialog.modal-lg {
    width: 900px;
  }
}

.modal-body {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-body.form-horizontal {
  max-height: none;
}

.validationMessage {
  display: block;
  color: #FF0000;
}

/*disable all form-controls under a container that is in error from changing border color*/
.has-error .form-control {
  border-color: #ccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.has-error .form-control:focus {
  border-color: #66afe9;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}


.has-error .control-label {
  color: #333;
}

.container {
  padding-left: 0;
  padding-right: 0;
}

i {
  margin-right: 8px;
}

button.close i {
  margin-right: 0;
}

.pagination i {
  margin-right: 0;
}

.form-inline {
  margin-left: 15px;
}

.toast-top-right {
  margin-top: 50px;
}

#statsContainer .row {
  text-align: center;
}

.card-body-small {
  padding: 3px 3px 3px 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.help-block {
  margin-bottom: 3px;
  display: block;
  margin-top: 5px;
  color: #737373;
}

.list-group-item-small {
  padding: 3px 3px 3px 5px;
}

textarea.form-control {
  height: 140px;
}

.form-group.has-feedback {
  position: relative;

  .form-control-feedback {
    position: relative;
    display: block;
    color: #999;
    padding: 4px 0 0 4px;
  }
}

.form-group .row {
  margin: 0 0 10px 0;
}

.input-group {
  margin-bottom: 10px;
}

.has-error {
  border: 1px solid #da4355 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

// turn off angular validation styling if we are using our form validation directive
.has-error input,
.has-error textarea {
  &.ng-invalid.ng-touched {
    border-color: #ced4da;
    box-shadow: none;
  }
}

.has-error ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #ccc;
  box-shadow: none;
}

div[class="tooltip-inner"] {
  width: 150px;
}

hr {
  height: 12px;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.25);
}

.tooltip.fade.top.in {
  z-index: 3000;
}

.row .btn-group-vertical {
  display: block;
}

table tr td.line-breaks,
table tr td span.line-breaks,
div.line-breaks,
.popover-content {
  white-space: pre-line;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.select2-container-multi ul.select2-choices {
  max-height: 300px;
  overflow-y: auto;
}

.select2-container-multi .select2-choices li.select2-search-choice {
  float: none;
  margin-right: 10px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: text;
}

div.full-width-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

i.fa-no-margin {
  margin-right: 0;
}

table.table th,
table.table tfoot tr {
  background-color: #f5f5f5;
}

div.form-control.textarea {
  height: 140px;
  overflow-y: scroll;
}

@media (min-width: 1000px) and (max-width: 1320px) {
  #app {
    margin-top: 75px;
  }
}

.has-switch {
  min-width: 250px;
}

span.normal {
  font-weight: normal !important;
}

.table-no-border>thead>tr>th,
.table-no-border>tbody>tr>th,
.table-no-border>tfoot>tr>th,
.table-no-border>thead>tr>td,
.table-no-border>tbody>tr>td,
.table-no-border>tfoot>tr>td {
  border-top: none;
}

.toast-message a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.inactive {
  color: $inactive;
}

.btn.btn-default:focus {
  background-color: inherit;
}

.btn.btn-default.active:focus {
  background-color: $default;
}

img.preview {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
}

// todo: convert to bootstrap mt spacing utility
.top10 {
  margin-top: 10px;
}

td .linkEllipsis {
  width: 250px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.floating-panel {
  position: fixed;
  width: 1000px;
  min-height: 50px;
  top: 215px;
  right: 15px;
  left: auto;
  z-index: 999;
  margin: 0;
  padding: 0;
}

.floating-panel-lg {
  width: 1000px;
}

.floating-panel-md {
  width: 800px;
}

.floating-panel-sm {
  width: 505px;
}

.floating-panel.collapsed {
  width: 55px;
  height: 55px;
  top: 215px;
  left: auto;
  right: 0;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.floating-panel .floating-panel-tab,
.floating-panel.collapsed .floating-panel-main {
  display: none;
}

.floating-panel .floating-panel-main,
.floating-panel.collapsed .floating-panel-tab {
  display: block;
}

.floating-panel .floating-panel-main,
.floating-panel .floating-panel-tab {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0 0 8px #666;
}

.floating-panel .floating-panel-main,
.floating-panel .floating-panel-main.panel {
  margin: 0;
}

.floating-panel .floating-panel-tab button {
  width: 100%;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
}

.floating-panel .floating-panel-btn-collapse,
.floating-panel .floating-panel-btn-expand {
  cursor: pointer;
}

.floating-panel .floating-panel-draggable {
  cursor: move;
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.float-panel {
  position: fixed;
  width: 505px;
  min-height: 50px;
  top: 215px;
  right: 15px;
  left: auto;
  z-index: 1999;
  margin: 0;
  padding: 0;

  &.collapsed {
    width: 55px;
    height: 55px;
    top: 215px;
    left: auto;
    right: 0;
    -moz-transition: .4s ease-out;
    -o-transition: .4s ease-out;
    -webkit-transition: .4s ease-out;
    transition: .4s ease-out;
  }

  .float-panel-tab,
  &.collapsed .float-panel-main {
    display: none;
  }

  .float-panel-main,
  &.collapsed .float-panel-tab {
    display: block;
  }

  .float-panel-main,
  .float-panel-tab {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 0 8px #666;
  }

  .float-panel-main,
  .float-panel-main.panel {
    margin: 0;
  }

  .float-panel-tab button {
    width: 100%;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
  }

  .float-panel-btn-collapse,
  .float-panel-btn-expand {
    cursor: pointer;
  }

  .float-panel-draggable {
    cursor: move;
  }
}

.float-panel-cu {
  position: fixed;
  width: 505px;
  min-height: 50px;
  top: 105px;
  right: 15px;
  left: auto;
  z-index: 999;
  margin: 0;
  padding: 0;
}

.float-panel-cu.collapsed {
  width: 55px;
  height: 55px;
  top: 105px;
  left: auto;
  right: 0;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.float-panel-cu .float-panel-cu-tab,
.float-panel-cu.collapsed .float-panel-cu-main {
  display: none;
}

.float-panel-cu .float-panel-cu-main,
.float-panel-cu.collapsed .float-panel-cu-tab {
  display: block;
}

.float-panel-cu .float-panel-cu-main,
.float-panel-cu .float-panel-cu-tab {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0 0 8px #666;
}

.float-panel-cu .float-panel-cu-main,
.float-panel-cu .float-panel-cu-main.panel {
  margin: 0;
}

.float-panel-cu .float-panel-cu-tab button {
  width: 100%;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 0;
}

.float-panel-cu .float-panel-btn-collapse,
.float-panel-cu .float-panel-btn-expand {
  cursor: pointer;
}

.float-panel-cu .float-panel-cu-draggable {
  cursor: move;
}

.float-panel-ctd {
  position: fixed;
  width: 505px;
  min-height: 50px;
  top: 325px;
  right: 15px;
  left: auto;
  z-index: 999;
  margin: 0;
  padding: 0;
}

.float-panel-ctd.collapsed {
  width: 55px;
  height: 55px;
  top: 325px;
  left: auto;
  right: 0;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.float-panel-ctd .float-panel-ctd-tab,
.float-panel-ctd.collapsed .float-panel-ctd-main {
  display: none;
}

.float-panel-ctd .float-panel-ctd-main,
.float-panel-ctd.collapsed .float-panel-ctd-tab {
  display: block;
}

.float-panel-ctd .float-panel-ctd-main,
.float-panel-ctd .float-panel-ctd-tab {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0 0 8px #666;
}

.float-panel-ctd .float-panel-ctd-main,
.float-panel-ctd .float-panel-ctd-main.panel {
  margin: 0;
}

.float-panel-ctd .float-panel-ctd-tab button {
  width: 100%;
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 0;
}

.float-panel-ctd .float-panel-btn-collapse,
.float-panel-ctd .float-panel-btn-expand {
  cursor: pointer;
}

.float-panel-ctd .float-panel-ctd-draggable {
  cursor: move;
}

.float-panel.compose {
  top: 160px;
  cursor: pointer;
}

.checklist {
  .checklist-item {
    cursor: pointer;
  }

  .checklist-item-complete {
    .checklist-item-title {
      text-decoration: line-through;
    }
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translate3d(0, -50px, 0);
  -ms-transform: translate3d(0, -50px, 0);
  -moz-transform: translate3d(0, -50px, 0);
  -o-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
  -webkit-transition: -webkit-transform .5s ease-out;
  -moz-transition: transform .5s ease-out;
  -o-transition: transform .5s ease-out;
  transition: transform .5s ease-out;

  &.pace-active {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 99999;
  }

  .pace-progress {
    display: block;
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 100%;
    width: 100%;
    height: 4px;
    background: #f0ad4e;
    -webkit-pointer-events: none;
    pointer-events: none;
  }
}

.label-badge {
  border-radius: 1em;
}

/* keyword research component */
.keywords-container {
  &>.row {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;

    &:nth-child(even) {
      background: #f7f7f7;
    }

    &:hover,
    &:nth-child(even):hover {
      background: #fcf8e3;
    }

    &.active {
      cursor: default;

      &,
      &:nth-child(even),
      &:hover {
        background: #faf2cc;
      }
    }

    &.empty {
      cursor: default;

      &,
      &:hover {
        background: 0;
      }
    }

    &.invalid .col-keyword-status {
      border-right: 4px solid #d9534f;
    }

    &.active.invalid .col-keyword-status {
      border-right: 0;
    }
  }

  textarea {
    height: 110px;
  }

  .label {
    margin: 0;
    display: inline-block;

    .fa {
      margin-right: 3px;
    }
  }

  .keyword-label-group {
    white-space: nowrap;
    font-size: 85%;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    .label,
    .badge {
      font-size: 100%;
      font-weight: normal;
    }
  }

  ul {
    margin: 0;

    li+li {
      margin-top: 10px;
    }
  }

  .keyword-note {
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .active .keyword-note {
    white-space: normal;
  }
}

.well+.keywords-container {
  margin-top: 20px;
}

.keywords-container+.well {
  margin-top: 20px;
  margin-bottom: 0;
}

@mixin transform-rotate ($rotation) {
  -moz-transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -o-transform: rotate($rotation);
  -webkit-transform: rotate($rotation);
  transform: rotate($rotation);
}

#keywords-component .popover {
  max-width: 700px;
  /* Notes are often long, overriding default popover width */
}

/* end keyword research component */

.editable {
  padding: 3px;
  cursor: pointer;
  border: 1px dotted transparent;
}

.editable:hover {
  border: 1px dotted black;
  background-color: #ffffe6;
}

.editable:empty {
  min-height: 50px;
}

.editable:empty:after {
  font-size: 11px;
  color: #ccc;
  content: 'Click to edit';
}

// Fix for badges inside labels.
.bg-warning {
  color: #fff;
}

.thumbnail.active {
  border-color: $primary;
  -moz-box-shadow: 0 0 6px $primary;
  -webkit-box-shadow: 0 0 6px $primary;
  box-shadow: 0 0 6px $primary;
}

.customer-account-default-group .panel {
  margin-bottom: 20px;
}

.bg-info {
  background-color: #d9edf7;
}

.bg-default {
  background-color: #f5f5f5;
}

.todo-toggle.collapsed .fa-caret-down {
  display: none;
}

.todo-toggle.collapsed .fa-caret-right {
  display: inline-block;
}

.todo-toggle .fa-caret-right {
  display: none;
}

.card-body-scroll {
  max-height: 500px;
  overflow-y: auto;
}

.inline-block-divs {
  display: inline-block;
  margin-right: 2%;
  width: 260px;
}

.help-next-customer-container {
  margin-top: 40px;
  margin-bottom: 20px;
}

ngb-popover-window[role=tooltip] {
  pointer-events: none;
}

#header .nav-link.active {
  background-color: #080808;
  color: #fff !important;
}

#header .navbar-dark .navbar-nav .nav-link {
  min-height: 50px;
  padding: 15px;
  font-size: 15px;
  color: #ccc;
}

.card {
  margin-bottom: 20px;
}

.float-panel-draggable.card-header, .float-panel-ctd-draggable.card-header, .float-panel-create-draft-draggable.card-header {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.col-form-label {
  text-align: right;
}

.list-group-item>.badge {
  float: right;
}

.btn-info, .btn-warning {
  color: #fff;
}

.input-group-text {
  cursor: pointer;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #428bca;
}

.icon-with-badge {
  padding: 9px 0;
}

.card-primary {
  border-color: $primary;
}

.card-primary .card-header {
  background-color: $primary;
  color: #fff;
}

.card-primary .card-header a {
  color: #fff !important;
}

.card-success {
  border-color: $success;
}

.card-success .card-header {
  background-color: $success;
  color: #fff;
}

.card-success .card-header a {
  color: #fff !important;
}

.card-danger {
  border-color: $danger;
}

.card-danger .card-header {
  background-color: $danger;
  color: #fff;
}

.card-danger .card-header a {
  color: #fff !important;
}

.card-warning {
  border-color: $warning;
}

.card-warning .card-header {
  background-color: $warning;
  color: #fff;
}

.card-warning .card-header a {
  color: #fff !important;
}

.card-secondary {
  border-color: $secondary;
}

.card-secondary .card-header {
  background-color: $secondary;
  color: #fff;
}

.card-secondary .card-header a {
  color: #fff !important;
}

.card-info {
  border-color: $info;
}

.card-info .card-header {
  background-color: $info;
  color: #fff;
}

.card-info .card-header a {
  color: #fff !important;
}

.table td button {
  white-space: nowrap;
}

.text-purple {
  color: #5b009f;
}

.dropdown-menu {
  // angular bootstrap dropdowns need higher z-index then the .fixed-bottom footer
  z-index: 1031;
}

.link-customer-icon {
  width: 16px;
  height: 16px;
  float: left;
}

.img-upload-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  max-width: 720px;
}
