/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "@ng-select/ng-select/themes/default.theme.css";

$primary: #428bca;
$secondary: #6c757d;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger
);

$custom-theme-colors: (
  "boost-blue": #428bca
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import "../node_modules/bootstrap/scss/bootstrap";

// styles for form validation + messages
// could move to component when validation is more standardized
div.validation-error {
  color: #da4355;
  font-size: 75%;
}

input,
textarea,
app-date-picker {
  &.ng-invalid.ng-touched {
    border-color: #da4355;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
    transition: border-color 250ms;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #da4355;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
  transition: border-color 250ms;
}

ng-select.hide-arrow .ng-arrow-wrapper  {
  display: none !important;
}

[hidden] {
  display: none !important;
}

.bg-dark {
  background-color: #343a40!important;
}

.card-header {
  padding: 0.75rem 1.25rem;
}

a {
  text-decoration: none !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary,
.btn-success,
.btn-success:hover,
.btn-success:active,
.btn-success:focus,
.btn-danger,
.btn-danger:focus,
.btn-warning,
.btn-outline-primary:hover,
.btn-outline-dark:hover,
.btn-outline-secondary:hover,
.btn-outline-primary.active {
  color: #fff !important;
}

.btn-outline-secondary {
  border-color: #6c757d !important;
}

.alert-info {
  background-color: #def2f8 !important;
}

// Bootstrap 3/4 classes that need to be here
.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

label {
  margin-bottom: 0.5rem;
}

.btn-close {
  float: right;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.popover.bs-popover-top {
  margin-top: -10px;
}
.popover.bs-popover-right {
  margin-left: 10px;
}
.popover.bs-popover-bottom {
  margin-top: 10px;
}
.popover.bs-popover-left {
  margin-left: -10px;
}
.popover.bs-popover-top .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px;
}
.popover.bs-popover-top .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #ffffff;
}
.popover.bs-popover-right .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-right .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #ffffff;
}
.popover.bs-popover-bottom .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px;
}
.popover.bs-popover-bottom .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #ffffff;
}
.popover.bs-popover-left .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-left .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #ffffff;
  bottom: -10px;
}
//end todo: remove these boostrap CSS hacks after we fully transition to bootstrap-4

:root {
  --star-size: 25px;
  --star-color: rgb(226, 226, 226);
  --star-background: #f0ad4e;
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

#wordcount {
  float: right;
  font-weight: bold;
}

.only-collapsed {
  display: none !important;
}

.collapsed .only-collapsed {
  display: inherit !important;
}

.collapsed .only-expanded {
  display: none !important;
}

.preserve-whitespace {
  white-space: pre-line;
}