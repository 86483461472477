.input-loadmask
{
	z-index: 100;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	zoom: 1;
}

.display-loadmask
{
	-moz-opacity: 0.5;
	opacity: .50;
	filter: alpha(opacity=50);
	background-color: #CCC;
}

.loadmask-msg
{
	z-index: 1020;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0px;
}

.loadmask-msg div
{
	border: 1px solid black;
	background-color: #333;
	padding: 5px 20px;
	color: white;
	cursor: wait;
	font-size: 24px;
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
}

.loadmask-msg div p
{
	margin: 0;
}

.masked
{
	overflow: hidden !important;
}

.masked-relative
{
	position: relative !important;
}

.masked-hidden
{
	visibility: hidden !important;
}
